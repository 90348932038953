<template>
  <div class="notification" :class="colorClass">
    <button class="delete" @click="$emit('remove', id)"></button>
    <slot>{{labelTr}}</slot>
  </div>
</template>

<script>
import label from './mixins/label';
import color from './mixins/color';

export default {
  name: 'SqrNotification',
  mixins: [label, color],
  props: {
    id: {type: [String, Number]}
  },
}
</script>
