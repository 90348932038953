<template>
  <main>
    <div class="section is-medium">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-6-desktop">
            <sqr-progress v-show="starting" />
            <div v-show="!starting">
              <h1 class="title is-4">{{ $t('signin_title') }}</h1>
              <form @submit="loginAndGo()" onsubmit="return false;" disabled>
                <error-notifications class="my-4" :errors="errors" />
                <div class="field">
                  <sqr-button
                    icon-prefix="fab"
                    icon="google"
                    label="signin_google"
                    @click="signInWithGoogle({})"
                    size="large"
                    color="primary"
                    is-fullwidth
                  />
                </div>
                <div class="field signin-or">
                  <span>{{ $t('signin_or') }}</span>
                  <hr />
                </div>
                <div class="field">
                  <sqr-input-email
                    label="signin_email"
                    name="email"
                    :value="email"
                    @change="fieldSet({ field: 'email', value: $event })"
                    size="medium"
                    :valid="!$v.email.$invalid"
                    :invalid="$v.email.$error"
                    @keyup.enter="loginAndGo()"
                  />
                  <div
                    class="help is-danger"
                    v-if="$v.email.$error && !$v.email.required"
                  >
                    {{ $t('validation_required') }}
                  </div>
                  <div
                    class="help is-danger"
                    v-else-if="$v.email.$error && !$v.email.email"
                  >
                    {{ $t('validation_email') }}
                  </div>
                </div>
                <div class="field">
                  <sqr-input-password
                    label="signin_password"
                    name="password"
                    autocomplete="password"
                    v-model="password"
                    size="medium"
                    :valid="!$v.password.$invalid"
                    :invalid="$v.password.$error"
                    @keyup.enter="loginAndGo()"
                  />
                  <div class="help is-danger" v-if="$v.password.$error">
                    {{ $t('validation_required') }}
                  </div>
                </div>
                <div class="is-pulled-right">
                  <sqr-router-link
                    label="signin_lost"
                    to-name="password-lost"
                    is-link
                  />
                </div>
                <div>
                  <sqr-router-link
                    label="signin_create"
                    to-name="register"
                    is-link
                  />
                </div>
                <div class="field">
                  <label class="label">&nbsp;</label>
                  <div class="control">
                    <sqr-button
                      icon="sign-in"
                      label="signin_login"
                      color="light"
                      @click="loginAndGo()"
                      size="large"
                      is-fullwidth
                      :is-loading="loading"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.signin-or {
  text-align: center;
  padding-top: 1rem;
  span {
    background: white;
    padding: 8px;
  }
  hr {
    margin: -0.7rem 0 2rem 0;
  }
}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';

import SqrInputEmail from '@/sqrd/SqrInputEmail';
import SqrInputPassword from '@/sqrd/SqrInputPassword';
import SqrRouterLink from '@/sqrd/SqrRouterLink';

import ErrorNotifications from './ErrorNotifications';

export default {
  name: 'SignIn',
  mixins: [validationMixin],
  components: {
    Logo: () => import(/* webpackChunkName: "Logo" */ '@/components/Logo.vue'),
    SqrInputPassword,
    SqrInputEmail,
    SqrRouterLink,
    ErrorNotifications
  },
  computed: {
    ...mapState('auth', ['starting', 'loading']),
    ...mapGetters('auth', ['authenticated', 'email', 'errors'])
  },
  data() {
    return {
      password: ''
    };
  },
  validations: {
    email: { email, required },
    password: { required }
  },
  watch: {
    authenticated(value) {
      if (value) {
        this.$router.replace({ name: 'brokers' });
      }
    }
  },
  mounted() {
    if (this.authenticated) {
      this.$router.replace({ name: 'brokers' });
    }
    this.reset({});
  },
  methods: {
    ...mapMutations('auth', ['fieldSet']),
    ...mapActions('auth', [
      'signInWithGoogle',
      'signInWithEmailAndPassword',
      'reset'
    ]),
    async loginAndGo() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.signInWithEmailAndPassword({
        email: this.email,
        password: this.password
      }).then(() => this.$router.push({ name: 'dashboard' }));
    }
  }
};
</script>
