<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{labelTr}}</label>
    <div class="control" :class="controlClass">
      <input
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholderTr"
        class="input"
        :class="inputClass"
        type="password"
        :aria-describedby="nameDesc"
        :value="value"
        @input="input"
        @change="change"
        v-on="listeners"
      >
      <span class="icon is-small is-left" v-if="icon"><i class="fa" :class="iconClass"></i></span>
      <span class="icon is-small is-right" v-if="valid"><i class="fal fa-check"></i></span>
    </div>
    <p class="help" v-if="helper">{{helperTr}}</p>
  </div>
</template>

<script>
  // check https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
  import input from './mixins/input';

  export default {
    name: 'sqr-input-password',
    mixins: [input]
  };
</script>
