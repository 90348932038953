export default {
  props: {
    placeholder: String,
    placeholderRaw: String
  },
  computed: {
    placeholderTr() {
      return this.placeholderRaw || this.$t(this.placeholder) || this.labelRaw || this.$t(this.label);
    }
  }
};
