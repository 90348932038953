export default {
  props: {
    helper: String,
    helperRaw: String
  },
  computed: {
    helperTr() {
      return this.helperRaw ? this.helperRaw : this.$t(this.helper);
    }
  }
};
